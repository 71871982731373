<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="pa-3">
          <v-toolbar>
            <v-toolbar-title>Recent Enquiries</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-title>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="cce_ref_no"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        @click:row="openDetail"
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>

import DlgMessage from '@/components/DlgMessage'
import ApCallcenterService from "@/services/ApCallcenterService"
export default {
    components:{DlgMessage},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Enquiry',disabled: false,href:'#',},
              {text:'Recent List',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Ref No.",value: "cce_ref_no"  },
                { text:"Channel", value:"cce_channel" },
                { text:"Type", value:"cce_type" },
                { text:"Rcv Date.", value:"rcv_date" },
                { text:"Subject", value:"cce_subject" },
                { text:"Status", value:"status" }                
            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,

        }
    },
    async created() {
      this.refreshDataTable();
    },
    methods:{
            async refreshDataTable(){
                this.loadingFlag = true
                try{
                    ApCallcenterService.list_recent(this.form).then((res) => {
                        //console.log(res);
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        if(e.response.data.error_message){
                            this.$refs.dlgMsg.showDlgBox(true,'error','Enquiry',e.response.data.error_message);
                        }else{
                            this.$refs.dlgMsg.showDlgBox(true,'error','Enquiry',e.response);
                        }
                        this.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','Enquiry','Unknown Error');
                  
                }           

            },
            openDetail(value){
                let routeData = this.$router.resolve({ 
                    name: 'callcenter-details', 
                    params: { 
                        cce_ref_no: value.cce_ref_no,
                        cc_enquiry_id:value.cc_enquiry_id
                    } 
                })
                window.open(routeData.href,'_blank')
            },
            dlgClose(){
                this.visible = false;
                if(!this.errorFlag)
                    this.refreshDataTable();
            },


    }

}
</script>